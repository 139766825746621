import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
:root{
  //SIZES
--px12: 0.75rem;  //12px
--px14: 0.875rem; //14px
--px16: 1rem;     //16px
--px18: 1.125rem; //18px
--px20: 1.25rem;  //20px
--px22: 1.375rem; //22px
--px24: 1.5rem;   //24px
--px28: 1.75rem;  //28px
--px30: 1.875rem; //30
--px32: 2rem;     //32px
--px35: 2.188rem; //32px
--px40: 2.5rem;   //40px
--px48: 3rem;     //45px
--px50: 3.125rem; //50px
--px55: 3.438rem; //55px
--px60: 3.75rem;  //60px
--px70: 4.375rem; //60px
--px80: 5rem;     //60px
// ==================================
// BREAK POINTS BASE BOOSTRAP
// ==================================
--sm: 576px;
--md: 768px;
--lg: 992px;
--xl: 1200px;
--xxl: 1400px;


--line-height-loose: 1.75;
--line-height-normal: 1.5;
--line-height-dense: 1.1;
--space-1: 4px;
--space-2: 8px;
--space-3: 16px;
--space-4: 24px;
--space-5: 32px;
--space-6: 64px;

--color-primary: #0f223d;
--color-code-bg: #0f223d;
--color-text: #333;
--color-purple:  #492e68;
--color-purple-dark: #2f1948;
--color-cyan:  #04d0c7;
--font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
body {

}

.navbar-collapse{
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background: ${props => props.theme.bgColor};
  font-family: 'Poppins', Helvetica, Sans-Serif;
  height: 100%;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width:576px) {
    font-size: 16px;
  }
}
  #___gatsby, #gatsby-focus-wrapper {
  height: 100%;
}

.index{
  .gallery{
    .col{
      max-height: 350px;
      margin-bottom: 30px;
    }
    .description {
    background-color: var(--color-cyan);
    color: #3b2158;
    display: flex;
    justify-content: center;
  
    margin-bottom: 30px!important;
    p{
      padding: 10px 50px;
    }
    .description {
       display: none;
      }
    }
  }


 
  }

#about, #skills, #getIntouch, #work {
    min-height: 100vh;
    overflow: hidden !important;
}
#work {
  background-color:var(--color-cyan);
}

#getIntouch{
  background-color:  var(--color-purple-dark);
  @media (max-width: 768px) {
    max-width: 100%!important;
    margin: auto;
  }
}


.internal{
  .wp-block-gallery {
  min-height: auto;
  img, svg {
  vertical-align: middle;
  height: 100%;
}
  }
}









.btn-primary {
  color: #2d1743;
  background-color: var(--color-cyan) !important;
  border-color: var(--color-cyan)  !important;
  &:hover{
    background-color: var(--color-cyan)   !important;
  border-color: var(--color-cyan)   !important;
  }
}
.form-group{
  margin-bottom: 20px;
  textarea, input {
  color: #3b2158 !important;
}
}

#getIntouch .animate__animated {
  height: 100%;
}

//Gallery for All gallery
.gallery{
  .col {
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  max-height: 400px;
  img {
    height: 100%;
    width: auto;
  }
  @media (min-width: 768px) {
    border: 3px solid transparent!important;
  }
  img{
      mix-blend-mode: multiply;
      -webkit-filter: grayscale(100%) contrast(1.2);
      filter: grayscale(100%) contrast(1);
      
    }
    &::after{
    background-color: #3b2057;
    mix-blend-mode: lighten;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all ease-in-out .5s;
  }
}
.open {
  figure{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    min-height: 100%;
    z-index: 99;
    border: 0px!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: #271639;
    .gatsby-image-wrapper-constrained > div{
      &::before {
        content: '×';
        position: absolute;
        display: flex !important;
        top: 85px;
        right: 70px;
        font-size: var(--px30);
        color: #080808;
        width: 30px;
        height: 30px;
        text-align: center;
        justify-content: center;
        align-items: center;
        background-color: var(--color-cyan);
        padding: 10px;
        border-radius: 50%;
        z-index: 99;
      }
    }

    img {
    height: 80%;
    width: auto;
    margin: auto;
    }
    & > div {
      margin: auto;
    }


  }
 
}


}

.tagcloud {
  font-size: var(--px32);
  font-weight: 600;
  color:var(--color-purple);
}
.progress-bar   {
  animation-duration: 3s;
  animation-name: slidein;
  color: #271639!important;
}
.progress {
  background-color: #170d23 !important;
  font-size: var(--px14);
  height: 1.4rem!important;
  border-radius: 4rem!important;

  &:hover{
    opacity: 0.8;
  }
}

@keyframes slidein {
  from {
    margin-left:-100%;
    opacity:0;
  }

  to {
    margin-left:0px;
    opacity:1;
  }
}

.text{
  &-purple{
  color:var(--color-purple);
}
  &-white{
      color:white
    }
  &-info {
    color: var(--color-cyan) !important;
  }

}

 
.title{
  color:var(--color-cyan);
  font-size: var(--px50);
  font-weight: 700;
}


#skills{
  background: var(--color-purple);
  h2{
    color:var(--color-cyan);
    font-size: var(--px50);
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 150%;
  }
  }
}


// ==================================
// Menus
// ==================================

.princiapal_menu {

    position: fixed !important;
    background-color: var(--color-purple);
    transition: all 1.5s;
    z-index: 10;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: hidden;
    z-index: 9999;
    &:hover{
      @media ((min-width: 768px)) {
          max-width: 150px;
      }
          .nav-item{
          span{
            opacity: 1!important;
            color: --color-cyan!important;
          }
          }
        }
      
      //socil
        .nav-link{
          &:hover{
            opacity: 0.8;
          }
        }

    @media ((min-width: 768px)) {
      background-color: var(--color-purple-dark);
    height: 100vh!important;
    min-width:inherit;
    max-width: 70px;
    z-index: 10!important;
    height: 100vh;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;

    }


    .navbar-nav{
      margin: auto;
      flex-direction: column !important;
      margin-left: 10px !important;
      @media ((max-width: 768px)) {
        height: 100vh;
      }
      .nav-item{
        white-space: nowrap;
        height: 10%;
        &:hover{
          opacity: 0.8;
        }
        span{
          transition: all 1.5s;
          opacity: 0;
   
          @media ((max-width: 768px)) {
            opacity: 1!important;
            font-size: var(--px18); 
            display: flex;
          } 

          &:hover{
          opacity: 0.8;
          }
        }

   
      }

    }

    .m_Social{
    border: 0px;
    bottom: 5%;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    @media (max-width:700px) {
    width: 90%;
    display: flex;
    position: absolute;
    text-align: center;
    justify-content: center;
    }
    span{
    display: none;
    }
    }

}


 
.mainWrap {
  position: relative;

  #about{
    background-color: var(--color-cyan);
    overflow: hidden;
    .about_img {
    z-index: 1;
    left: 0px;
      display: flex;
      @media ((min-width: 768px)) {
      position: absolute;
      height: 100vh;
    }
 
    @media (max-width: 768px) {
    position: relative;
    border: #3b2057 0px solid;
    width: auto;
    min-height: 400px;
    height: auto;
    border-radius:0%;
     overflow: hidden;

    .gatsby-image-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 70vw;
    min-height: auto;
    height: 70vw;
    border-radius: 50%;
    border: #3b2057 10px solid;
    margin: auto;
    img{
      height: 70vw;
      width: 105vw;
    }
    }
    }
 
  
  }
  .about_content{
    position: relative;
  z-index: 2;
  min-height:100vh;
  .big-heading {
    font-size: var(--px50);
    font-weight: 700;
  
  }
  h3{
    font-size: var(--px24);
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 150%;
    }
  }
    }
  }


}

.mapImg {
    overflow: hidden;
  div{
  width: 100%;
  height: auto;
}
  @media (max-width: 768px) {
    max-height: 300px;
    height: 600px;
  overflow: hidden;
  }

}

.map{
  display: none;
  width:50%;
  object-fit: contain;
  @media only screen and (min-width: 800px){
    display: flex;
  }
}
// ==================================
// carousel-indicators
// ==================================


#carouselExampleIndicators{
    h3{
      color:  var(--color-purple);
      font-size: var(--px50);
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 150%;
    }
    }
    p{
      color:  var(--color-purple);
    }
  .arrow-work {
  position: absolute;
  top: -15px;
}
  .carousel-indicators{
    margin-bottom: 0px!important;
  }

}

// ==================================
// gallery
// ==================================
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
align-items: center;
  position: relative;
  min-height: 800px;
  .wp-block-image {
    height: 300px;
  width: auto;
  overflow: hidden;
  border: 5px transparent solid;
  background: transparent;

  &.open{
    overflow: visible;
    z-index: 99;
    height: auto;
    position: absolute;
    &::before {
        content: '×';
        position: absolute;
        display: flex !important;
        top: 0px;
        right: 0px;
        font-size: var(--px30);
        color: #080808;
        width: 30px;
        height: 30px;
        text-align: center;
        justify-content: center;
        align-items: center;
        background-color: var(--color-cyan);
        padding: 10px;
        border-radius: 50%;
        z-index: 99;
      }
  }
}
figure{
  figcaption {
  display: none;
}
}
  &.blackOver{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    align-content: center;
    img, svg{
      height: auto;
      max-height: 800px;
    }
     & figure{
      z-index: 10;
      figcaption {
      display: flex;
      }
   
    }
    &::before{
      content: '';
      display: flex;
      min-width: 100%;
      height: 100%;
      position: absolute;
      z-index: 99;
      background: #000000f2;
    }
    
   
  }
}

.columns{

&-3, &-4, &-5{

.blocks-gallery-grid{
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: space-between;

  li{
    max-width: 32%;
  }

}

}

&-2{

  figure{
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 50%;
  }
  @media (max-width: 576px) {
    max-width: 100%;
  }
  }
}
&-3{
  figure{
  max-width: 33%;
  @media (max-width: 768px) {
    max-width: 50%;
  }
  @media (max-width:576px) {
    max-width: 100%;
  }
  }
}
&-4{
display: flex;
flex-wrap: wrap;
figure{
  max-width: 25%;
  @media (max-width: 768px) {
    max-width: 50%;
  }
  @media (max-width:576px) {
    max-width: 100%;
  }
  }
}

.blocks-gallery-item__caption {
display: none;
}


}


//WITH COL
.green{
    .gatsby-image-wrapper-constrained{
    background-color: var(--color-cyan);
    min-height: 100%;
      img{
      mix-blend-mode: multiply;
      -webkit-filter: grayscale(100%) contrast(1.2);
      filter: grayscale(100%) contrast(1);
      
    }
  }

  &::after{
  background-color: #3b2057;
  mix-blend-mode: lighten;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all ease-in-out .5s;
  }

  &:hover{
    .gatsby-image-wrapper-constrained{
      mix-blend-mode: unset !important;
      background: transparent !important;
      img{
        filter: none!important;
        mix-blend-mode: unset !important;
      background: transparent !important;
      }
      
    }
 
    &::after{
      filter: none!important;
    mix-blend-mode: unset !important;
    background: transparent;
      }

}


  
}

 


.wp-block-group__inner-container{
    margin-top: 60px;
    margin-bottom: 40px;
  }
.subtitle {
  padding-bottom: 40px;
}

 


 
.btn-secondary {
  color: #fff;
  background-color: #2c0e49  !important;
  border-color: #2c0e49  !important;
  &:hover{
  background-color: #5a2988!important;
  border-color: #5a2988!important;
}
}

.btn-secondary:hover{
  background-color: #5a2988  ;
  border-color: #5a2988 ;
}
ul {
  list-style: none;
  padding-left: 0px!important;
}
.h-100vh{
  height: 100vh;
}






`

export default GlobalStyle
