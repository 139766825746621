import React, { useEffect } from "react"
import { Link } from "gatsby"

const MenuMain = ({ menu_principal, menu_social }) => {
  const m_principal = menu_principal.menuItems.nodes
  const m_Social = menu_social.menuItems.nodes

  useEffect(() => {
    const items = document.querySelectorAll(".nav-link")

    function addClass(e) {
      // Remove 'active' from all items
      items.forEach(item => item.classList.remove("active"))
      // Wait for a moment before adding 'active' to the clicked item
      setTimeout(() => {
        e.target.classList.add("active")
      }, 500)
    }

    // Attach the click event listener to all '.nav-link' items
    items.forEach(item => {
      item.addEventListener("click", addClass)
    })

    // Clean up the event listeners when the component unmounts
    return () => {
      items.forEach(item => {
        item.removeEventListener("click", addClass)
      })
    }
  }, [])

  return (
    <nav className="princiapal_menu small navbar col-12 col-md-2 navbar-expand-md navbar-dark flex-shrink-1 p-4 p-md-0">
      <button
        className="navbar-toggler ms-auto me-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav" aria-label="Principal navigation">
          {m_principal.map(item => (
            <li key={item.label} className="nav-item">
              <Link
                className="nav-link d-flex justify-content-start align-items-center"
                aria-current="page"
                to={item.url}
              >
                <i
                  className={`bi ${item.cssClasses.join(" ")} text-info`}
                  style={{ fontSize: 25 }}
                ></i>
                <span className="ms-4 ms-md-2">{item.label}</span>
              </Link>
            </li>
          ))}

          <ul className="m_Social" aria-label="Social Icons navigation">
            {m_Social.map(item2 => (
              <li key={item2.label} className="nav-item2">
                <a
                  className="nav-link"
                  aria-current="page"
                  target="_blank" // Open links in a new tab
                  rel="noopener noreferrer" // Add security attribute
                  href={item2.url}
                >
                  <i
                    className={`bi ${item2.cssClasses.join(" ")} text-info`}
                    style={{ fontSize: 25 }}
                  ></i>
                  <span className="ms-2">{item2.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </ul>
      </div>
    </nav>
  )
}

export default MenuMain
