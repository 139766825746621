import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import GlobalStyle from "../components/GlobalStyle"
import MenuMain from "./Menu_main"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query globalSettings {
      site {
        siteMetadata {
          description
          title
        }
      }
      allWp {
        nodes {
          global {
            acf_global {
              bgColor
              fieldGroupName
              textColor
            }
            pageTitle
          }
        }
      }
      allWpMenu {
        nodes {
          name
          slug
          menuItems {
            nodes {
              url
              title
              target
              cssClasses
              label
            }
          }
        }
      }
    }
  `)

  const menus = data.allWpMenu.nodes
  const menu_principal = menus[0].slug === "principal" ? menus[0] : menus[1]
  const menu_social = menus[0].slug === "menu-social" ? menus[0] : menus[1]
  const { bgColor, textColor } = data.allWp.nodes[0].global.acf_global

  useEffect(() => {
    const menu = document.querySelector("nav")
    const menuList = menu.querySelectorAll("li")

    menuList.forEach(item => {
      //Show menu Principal
      item.addEventListener("click", function (event) {
        if (document.querySelector(".show")) {
          const show = document.querySelector(".show")
          show.classList.remove("show")
        }
      })
    })

    return () => {}
  }, [])

  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <main
      className={
        url === "/" || url === "http://localhost:8000/"
          ? "index"
          : "internal  h-100"
      }
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <GlobalStyle theme={bgColor} />
      <div className="mainWrap d-flex  flex-row-reverse ">
        <MenuMain
          tabIndex={0}
          menu_principal={menu_principal}
          menu_social={menu_social}
        />
        <main className="col-12 pt-5 me-md-auto ">{children}</main>
      </div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

export const Head = ({ Context }) => {
  let title = "test"
  let description = "test2"
  if (Context) {
    title = Context.title
    description = Context.description
  }
  console.log(Context)
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
    </>
  )
}
