import React from "react"
import Layout from "../components/layout"
const adminPage = () => (
  <Layout>
    <h1>Not Here 404</h1>

    <h1>
      <a
        href="https://www.mariolafuente-admin.sitecr.com/wp-admin"
        target="_blank"
        rel="noopener noreferrer"
      >
        _
      </a>
    </h1>
  </Layout>
)

export default adminPage
